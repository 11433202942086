import React from 'react';

interface PasswordRequirementsProps {
  customRequirements?: React.ReactNode;
}

export const PasswordRequirements: React.FC<PasswordRequirementsProps> = ({
  customRequirements
}) => {
  return (
    <div className="password-requirements">
      <p>Password must:</p>
      <ul>
        <li>Be 8-64 characters long</li>
        <li>Have at least one uppercase letter (A-Z)</li>
        <li>Have at least one lowercase letter (a-z)</li>
        <li>Contain at least one number (0-9)</li>
        <li>
          Have at least one special character (e.g., !@#$%^&*()_+-=[]{}
          |;&apos;:&quot;,.&lt;&gt;?)
        </li>
        {customRequirements}
      </ul>
    </div>
  );
};
