import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../state';
import { getSubscriptions } from '../../../state/actions/userActions';

export const useLoadSubscriptions = () => {
  const dispatch = useAppDispatch();
  const subscriptions = useAppSelector((state) => state.user.subscriptions);
  const loadingSubscriptions = useAppSelector(
    (state) => state.user.loadingSubscriptions
  );
  const subscriptionsError = useAppSelector(
    (state) => state.user.subscriptionsError
  );

  useEffect(() => {
    if (!subscriptions) {
      dispatch(getSubscriptions());
    }
  }, [dispatch, subscriptions]);

  return { subscriptions, loadingSubscriptions, subscriptionsError };
};
