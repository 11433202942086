import { FC } from 'react';

interface GeneralSubscriptionTabsProps {
  activeTab: 'general' | 'subscription';
  setActiveTab: React.Dispatch<
    React.SetStateAction<'general' | 'subscription'>
  >;
}

export const GeneralSubscriptionTabs: FC<GeneralSubscriptionTabsProps> = ({
  activeTab,
  setActiveTab
}) => {
  const handleGeneralClick = () => {
    setActiveTab('general');
  };

  const handleSubscriptionClick = () => {
    setActiveTab('subscription');
  };

  return (
    <main className="flex flex-col justify-start w-full">
      <div className="tabs-row flex justify-start px-4">
        <div className="tabs max-w-3xl w-full m-auto">
          <button
            className={`tab-button py-4 ${activeTab === 'general' ? 'active' : ''}`}
            onClick={handleGeneralClick}
          >
            <span className="general-label">General</span>
          </button>
          <button
            className={`tab-button py-4 ${activeTab === 'subscription' ? 'active' : ''}`}
            onClick={handleSubscriptionClick}
          >
            <span className="subscription-label">Subscription</span>
          </button>
        </div>
      </div>
    </main>
  );
};
