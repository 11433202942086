export const SET_VIEW_MODE = 'SET_VIEW_MODE';
export const SET_CITES_CITEDBY_VIEW_MODE = 'SET_CITES_CITEDBY_VIEW_MODE';
export const SET_SUBSCRIPTIONS_VIEW_MODE = 'SET_SUBSCRIPTIONS_VIEW_MODE';

export type ViewMode = 'single' | 'triple';

export const setViewMode = (mode: ViewMode) => ({
  type: SET_VIEW_MODE as typeof SET_VIEW_MODE,
  payload: mode
});

export const setViewModeCitesCitedBy = (mode: ViewMode) => ({
  type: SET_CITES_CITEDBY_VIEW_MODE as typeof SET_CITES_CITEDBY_VIEW_MODE,
  payload: mode
});

export const setSubscriptionsViewMode = (mode: ViewMode) => ({
  type: SET_SUBSCRIPTIONS_VIEW_MODE as typeof SET_SUBSCRIPTIONS_VIEW_MODE,
  payload: mode
});
