import { FC } from 'react';
import { useAuth } from '../../state/hooks/useAuth';
import { ProfileImg } from '../../svgs/ProfileImg';
import { Tooltip } from '../core/tooltip';
import { ProfileResetPassword } from '../../routes/reset-password/profileResetPassword';

export const GeneralContent: FC = () => {
  const { user } = useAuth();
  const [firstName, lastName] = (user?.displayName ?? '').split(' ');
  return (
    <div className="subscription-item item itemPage cursor-default border border-gray-200 rounded px-8 py-6 shadow-sm w-full">
      <div className="general-title">General Info</div>
      <div className="flex justify-between items-center generalInfo">
        <div className="">
          <div className="">
            Name: <span className="text-primary-500">{firstName}</span>
          </div>
          <div className="">
            Surname: <span className="text-primary-500">{lastName}</span>
          </div>
          <div className="">
            Email:{' '}
            <span className="text-primary-500">{user?.emailAddress}</span>
          </div>
        </div>
        <div className="profileImg cursor-pointer">
          <Tooltip
            className="updatePass"
            text="The Profile Picture will be available soon"
          >
            <ProfileImg></ProfileImg>
          </Tooltip>
        </div>
      </div>

      <div className="general-title mt-10">Password Change</div>
      <ProfileResetPassword />
    </div>
  );
};
