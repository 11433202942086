import React, { FC, useEffect, useState, useCallback, useRef } from 'react';
import { Formik, Form, Field, FormikProps } from 'formik';
import * as Yup from 'yup';
import { Button, Input, Label, Validation } from '../../components/form';
import { passwordValidationSchema } from '../register/register';
import { QuestionMark, VisibilityHidden, VisibilityShow } from '../../svgs';
import { Tooltip } from '../../components/core/tooltip';
import { NotificationModalWrapper } from '../../components/modals';
import { useAuth } from '../../state/hooks/useAuth';
import { PasswordRequirements } from './passwordRequirements';
import { env } from '../../env';

const initialValues = {
  currentPassword: '',
  newPassword: '',
  confirmNewPassword: ''
};

type FormData = typeof initialValues;

export const ProfileResetPassword: FC = () => {
  const { forgotPassword, changePassword, user } = useAuth();
  const formikRef = useRef<FormikProps<FormData>>(null);
  const [notificationType, setNotificationType] = useState<
    'none' | 'success' | 'error'
  >('none');
  const [isNotificationOpen, setNotificationOpen] = useState(false);
  const [notificationTitle, setNotificationTitle] = useState('');
  const [notificationMessage, setNotificationMessage] =
    useState<React.ReactNode>('');
  const [countdown, setCountdown] = useState(5);

  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);

  const [isForgotPasswordLoading, setIsForgotPasswordLoading] = useState(false);
  const [isForgotPasswordSuccess, setIsForgotPasswordSuccess] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [showForgotPassword, setShowForgotPassword] = useState(false);

  const validationSchema = Yup.object().shape({
    currentPassword: Yup.string()
      .required('Current Password is required')
      .min(8, 'Current Password must be at least 8 characters long')
      .max(64, 'Current Password must not exceed 64 characters'),
    newPassword: passwordValidationSchema,
    confirmNewPassword: showNewPassword
      ? Yup.string().notRequired()
      : Yup.string()
          .oneOf([Yup.ref('newPassword')], 'Passwords must match')
          .required('Confirm New Password is required')
  });

  const togglePasswordVisibility = (
    field: 'currentPassword' | 'newPassword'
  ) => {
    if (field === 'currentPassword') {
      setShowCurrentPassword((prev) => !prev);
    } else if (field === 'newPassword') {
      setShowNewPassword((prev) => !prev);
    }
  };

  const startCountdown = useCallback(() => {
    const interval = setInterval(() => {
      setCountdown((prev) => {
        if (prev === 1) {
          clearInterval(interval);
        }
        return prev - 1;
      });
    }, 1000);
  }, []);

  useEffect(() => {
    if (formikRef.current) {
      formikRef.current.validateForm();
    }
  }, [showNewPassword]);

  useEffect(() => {
    if (notificationType === 'success' && countdown > 0) {
      setNotificationMessage(
        <>
          Your password has been successfully updated. Modal window will close
          automatically in {countdown} second
          {countdown > 1 ? 's' : ''}.
        </>
      );
    }
  }, [countdown, notificationType]);

  useEffect(() => {
    if (
      notificationType === 'success' &&
      countdown === 0 &&
      isNotificationOpen
    ) {
      setNotificationOpen(false);
      setNotificationType('none');
    }
  }, [notificationType, countdown, isNotificationOpen]);

  const handleSubmit = async (
    values: typeof initialValues,
    { setFieldError, resetForm }: any
  ) => {
    setIsLoading(true);
    try {
      const email = user?.emailAddress;
      if (!email) {
        throw new Error('User email is not available.');
      }

      const changePasswordData = {
        currentPassword: values.currentPassword,
        newPassword: values.newPassword
      };
      await changePassword(changePasswordData);
      setNotificationTitle('Success');
      setNotificationType('success');
      setNotificationMessage(
        <>
          Your password has been successfully updated. Modal window will close
          automatically in {countdown} second
          {countdown > 1 ? 's' : ''}.
        </>
      );
      setNotificationOpen(true);
      setCountdown(5);
      startCountdown();
      resetForm();
      setShowForgotPassword(false);
    } catch (err: any) {
      let errorMessage;
      setNotificationType('error');
      if (err === 'Incorrect current password') {
        setFieldError('currentPassword', err);
        errorMessage =
          'There was a problem updating your password. Please try again.';
        setNotificationMessage(errorMessage);
      } else {
        errorMessage = err;
        setFieldError('newPassword', errorMessage);
        setNotificationMessage(errorMessage);
      }
      setNotificationTitle('Error');
      setNotificationOpen(true);
      setShowForgotPassword(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleNotificationClose = () => {
    setNotificationOpen(false);
    setNotificationTitle('');
    setNotificationMessage('');
    setCountdown(5);
    setNotificationType('none');
  };

  const handleForgotPassword = async () => {
    if (isForgotPasswordLoading || isForgotPasswordSuccess) return;

    setIsForgotPasswordLoading(true);
    try {
      const email = user?.emailAddress;
      if (!email) {
        throw new Error('User email is not available.');
      }

      await forgotPassword({ email, host: env.apiUrl! });
      setNotificationTitle('Success');
      setNotificationMessage(
        'A password reset link has been sent to your email address.'
      );
      setNotificationOpen(true);
      setIsForgotPasswordSuccess(true);
    } catch (err: any) {
      // eslint-disable-next-line no-console
      console.error('Error sending forgot password email:', err);
      let errorMessage =
        'There was a problem sending the password reset email. Please try again.';
      if (err.response && err.response.data && err.response.data.errors) {
        errorMessage = err.response.data.errors.join(' ');
      } else if (err.message) {
        errorMessage = err.message;
      }
      setNotificationTitle('Error');
      setNotificationMessage(errorMessage);
      setNotificationOpen(true);
    } finally {
      setIsForgotPasswordLoading(false);
    }
  };
  return (
    <div className="max-w-full w-full flex flex-col items-center generalInfoForm">
      <Formik
        innerRef={formikRef}
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize={true}
        onSubmit={handleSubmit}
      >
        {({
          handleChange,
          handleBlur,
          values,
          errors,
          touched,
          isValid,
          isSubmitting,
          setFieldError
        }) => (
          <Form className="w-full mx-auto register-form">
            <div className="mb-4 max-w-full">
              <Label htmlFor="currentPassword">Current Password</Label>
              <div className="relative">
                <Field
                  as={Input}
                  id="currentPassword"
                  name="currentPassword"
                  type={showCurrentPassword ? 'text' : 'password'}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    handleChange(e);
                    if (
                      errors.currentPassword === 'Incorrect current password'
                    ) {
                      setFieldError('currentPassword', '');
                    }
                  }}
                  onBlur={handleBlur}
                  value={values.currentPassword}
                  className={`pr-5 ${
                    touched.currentPassword && errors.currentPassword
                      ? 'border-error-500'
                      : ''
                  } ${
                    touched.currentPassword && !errors.currentPassword
                      ? 'border-green-500'
                      : ''
                  }`}
                  placeholder="********"
                />
                <button
                  type="button"
                  className="absolute right-0 top-0 mt-3 mr-2 focus:outline-none"
                  onClick={() => togglePasswordVisibility('currentPassword')}
                  aria-label={
                    showCurrentPassword
                      ? 'Hide current password'
                      : 'Show current password'
                  }
                  aria-pressed={showCurrentPassword}
                >
                  {showCurrentPassword ? (
                    <VisibilityShow />
                  ) : (
                    <VisibilityHidden />
                  )}
                </button>
              </div>
              {errors.currentPassword && touched.currentPassword && (
                <Validation message={errors.currentPassword} />
              )}
            </div>

            <div className="mb-4 max-w-full">
              <div className="flex justify-between">
                <Label htmlFor="newPassword">New Password</Label>
                <Tooltip
                  className="tooltipPassword tooltipResetPassword updatePass"
                  text={<PasswordRequirements />}
                >
                  <QuestionMark />
                </Tooltip>
              </div>
              <div className="relative">
                <Field
                  as={Input}
                  id="newPassword"
                  name="newPassword"
                  type={showNewPassword ? 'text' : 'password'}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.newPassword}
                  className={`pr-5 ${
                    touched.newPassword && errors.newPassword
                      ? 'border-error-500'
                      : ''
                  } ${
                    touched.newPassword && !errors.newPassword
                      ? 'border-green-500'
                      : ''
                  }`}
                  placeholder="********"
                />
                <button
                  type="button"
                  className="absolute right-0 top-0 mt-3 mr-2 focus:outline-none"
                  onClick={() => togglePasswordVisibility('newPassword')}
                  aria-label={
                    showNewPassword ? 'Hide new password' : 'Show new password'
                  }
                  aria-pressed={showNewPassword}
                >
                  {showNewPassword ? <VisibilityShow /> : <VisibilityHidden />}
                </button>
              </div>
              {errors.newPassword && touched.newPassword && (
                <Validation message={errors.newPassword} />
              )}
            </div>

            {!showNewPassword && (
              <div className="mb-4 max-w-full">
                <Label htmlFor="confirmNewPassword">Confirm New Password</Label>
                <Field
                  as={Input}
                  id="confirmNewPassword"
                  name="confirmNewPassword"
                  type="password"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.confirmNewPassword}
                  className={`pr-5 ${
                    touched.confirmNewPassword && errors.confirmNewPassword
                      ? 'border-error-500'
                      : ''
                  } ${
                    touched.confirmNewPassword && !errors.confirmNewPassword
                      ? 'border-green-500'
                      : ''
                  }`}
                  placeholder="********"
                />
                {errors.confirmNewPassword && touched.confirmNewPassword && (
                  <Validation message={errors.confirmNewPassword} />
                )}
              </div>
            )}

            <div className="flex justify-center generalBtns">
              <Button
                type="button"
                className="px-8 py-2 max-w-xsm w-full mr-2"
                onClick={handleForgotPassword}
                disabled={
                  !showForgotPassword ||
                  isForgotPasswordLoading ||
                  isForgotPasswordSuccess
                }
              >
                {isForgotPasswordLoading
                  ? 'Sending...'
                  : isForgotPasswordSuccess
                    ? 'Password Reset Email Sent'
                    : 'Forgot Password?'}
              </Button>
              <Button
                type="submit"
                className="px-8 py-2 max-w-xsm w-full"
                disabled={
                  !isValid ||
                  isLoading ||
                  isSubmitting ||
                  (!values.currentPassword && !values.newPassword)
                }
              >
                {isLoading || isSubmitting ? 'Updating...' : 'Update Password'}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
      <div className=" whitespace-pre-wrap">
        <NotificationModalWrapper
          isOpen={isNotificationOpen}
          title={notificationTitle}
          message={notificationMessage}
          onClose={handleNotificationClose}
        />
      </div>
    </div>
  );
};
