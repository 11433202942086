import { GeneralSubscriptionTabsContainer } from '../../components/generalSubscriptionTabs/generalSubscriptionTabsContainer';

export const MyProfilePage = () => {
  return (
    <div className="w-full h-full">
      <div className="paperBorder"></div>
      <GeneralSubscriptionTabsContainer />
    </div>
  );
};
