import {
  SET_CITES_CITEDBY_VIEW_MODE,
  SET_SUBSCRIPTIONS_VIEW_MODE,
  SET_VIEW_MODE,
  setSubscriptionsViewMode,
  setViewMode,
  setViewModeCitesCitedBy,
  ViewMode
} from '../actions';

export interface ViewState {
  generalMode: ViewMode;
  citesCitedByMode: ViewMode;
  subscriptionsMode: ViewMode;
}

const initialState: ViewState = {
  generalMode: 'single',
  citesCitedByMode: 'single',
  subscriptionsMode: 'single'
};

type ViewActions =
  | ReturnType<typeof setViewMode>
  | ReturnType<typeof setViewModeCitesCitedBy>
  | ReturnType<typeof setSubscriptionsViewMode>;

export function viewReducer(
  state = initialState,
  action: ViewActions
): ViewState {
  switch (action.type) {
    case SET_VIEW_MODE:
      return { ...state, generalMode: action.payload };
    case SET_CITES_CITEDBY_VIEW_MODE:
      return { ...state, citesCitedByMode: action.payload };
    case SET_SUBSCRIPTIONS_VIEW_MODE:
      return { ...state, subscriptionsMode: action.payload };
    default:
      return state;
  }
}
