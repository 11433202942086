import { AppState } from '../store';

export const selectUserProfile = (state: AppState) => state.user.profile;
export const selectIsUserAdmin = (state: AppState) =>
  state.user.profile?.superUser || false;
export const selectUserId = (state: AppState) => state.user.profile?.id || null;

export const selectSubscriptions = (state: AppState) =>
  state.user.subscriptions;
export const selectLoadingSubscriptions = (state: AppState) =>
  state.user.loadingSubscriptions;
export const selectSubscriptionsError = (state: AppState) =>
  state.user.subscriptionsError;
