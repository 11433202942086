import { useEffect, useState } from 'react';
import { GeneralSubscriptionTabs } from './generalSubscriptionTabs';
import { GeneralContent } from './generalContent';
import { SubscriptionContent } from './subscriptionContent';
import { useNavigate, useParams } from 'react-router-dom';

export const GeneralSubscriptionTabsContainer = () => {
  const { type } = useParams();
  const navigate = useNavigate();

  const defaultTab = type === 'subscription' ? 'subscription' : 'general';
  const [activeTab, setActiveTab] = useState<'general' | 'subscription'>(
    defaultTab
  );

  useEffect(() => {
    if (type !== activeTab) {
      navigate(`/profiles/my/${activeTab}`, { replace: true });
    }
  }, [activeTab, type, navigate]);

  return (
    <div className="h-full w-full">
      <div className="flex flex-col h-full w-full">
        <GeneralSubscriptionTabs
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
        <div className="maincont flex flex-row w-full">
          <div className="pagepart w-full h-full pt-6 pb-6 px-4">
            <div className="flex w-full max-w-3xl mx-auto">
              {activeTab === 'general' ? (
                <GeneralContent />
              ) : activeTab === 'subscription' ? (
                <SubscriptionContent />
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
