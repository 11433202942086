import { FC, useEffect, useState } from 'react';
import { useAppSelector } from '../../state';
import {
  selectLoadingSubscriptions,
  selectSubscriptions,
  selectSubscriptionsError
} from '../../state/selectors/userSelector';
import { NotificationModalWrapper } from '../../components/modals';
import { AccordionItemStickyCitesCitedBy } from '../accordion/accordionItemStickyCitesCitedBy';
import { SubscriptionsViewToggle } from './subscriptionViewToggle';
import { SubscriptionsList } from './subscriptionListItems';
import { Loading } from '../core';
import { useCountdownForNavigate } from '../../routes/post/hooks/useCountdownForNavigate';
import { selectSubscriptionsViewMode } from '../../state/selectors/viewModeSelector';
import { ErrorComponent } from '../core/error';

type ConfirmationType = 'subscribe' | 'unsubscribe' | null;

export const SubscriptionContent: FC = () => {
  const subscriptions = useAppSelector(selectSubscriptions);
  const loading = useAppSelector(selectLoadingSubscriptions);
  const error = useAppSelector(selectSubscriptionsError);
  const subscriptionsMode = useAppSelector(selectSubscriptionsViewMode);
  const [unsubscribedCommunityName, setUnsubscribedCommunityName] = useState<
    string | null
  >(null);
  const [isNotificationModalOpen, setIsNotificationModalOpen] = useState(false);
  const [confirmationType, setConfirmationType] =
    useState<ConfirmationType>(null);

  const [isExpanded, setIsExpanded] = useState<boolean>(true);
  const countdown = useCountdownForNavigate(
    confirmationType === 'unsubscribe' && isNotificationModalOpen ? 5 : null
  );
  useEffect(() => {
    if (countdown === 0 && isNotificationModalOpen) {
      setIsNotificationModalOpen(false);
    }
  }, [confirmationType, countdown, isNotificationModalOpen]);

  const hasSubscriptions =
    subscriptions &&
    subscriptions.communityNews &&
    subscriptions.communityNews.length > 0;
  const disabled = !hasSubscriptions;

  const handleToggle = () => {
    setIsExpanded((prev) => !prev);
  };

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <ErrorComponent error={error}></ErrorComponent>;
  }

  if (!hasSubscriptions && !loading) {
    return <div>No subscriptions found.</div>;
  }

  const closeNotificationModal = () => {
    setIsNotificationModalOpen(false);
    setConfirmationType(null);
    setUnsubscribedCommunityName(null);
  };

  return (
    <div className="max-w-full w-full">
      <div className="mb-0 sticky-accordion-container accordionPapers accordionCommunities">
        <AccordionItemStickyCitesCitedBy
          title="Your Subscriptions"
          isExpanded={isExpanded}
          onToggle={handleToggle}
          disabled={disabled}
          tooltipText={
            disabled
              ? 'Unfortunately we don’t have any subscriptions information.'
              : undefined
          }
          isCitesCitedBy={false}
        >
          <SubscriptionsViewToggle viewMode={subscriptionsMode} />
          <SubscriptionsList
            items={subscriptions ? subscriptions.communityNews : []}
            viewMode={subscriptionsMode}
            setConfirmationType={setConfirmationType}
            setIsNotificationModalOpen={setIsNotificationModalOpen}
            setUnsubscribedCommunityName={setUnsubscribedCommunityName}
          />
        </AccordionItemStickyCitesCitedBy>
      </div>
      {confirmationType === 'unsubscribe' && isNotificationModalOpen && (
        <NotificationModalWrapper
          isOpen={isNotificationModalOpen}
          title="Success"
          message={`You are no longer subscribed to ${unsubscribedCommunityName}`}
          onClose={closeNotificationModal}
        />
      )}
    </div>
  );
};
