import { FC } from 'react';
import { HomeTopBar } from '../../components/core/topBars';
import { NotFound } from '../not-found';
import { useAuth } from '../../state/hooks/useAuth';
import { MyProfilePage } from './MyProfilePage';
import { useLoadSubscriptions } from '../../components/generalSubscriptionTabs/hooks/useLoadSubscriptions';
interface Props {}

export const MyProfileContainer: FC<Props> = () => {
  const { user } = useAuth();
  useLoadSubscriptions();

  if (!user) {
    return <NotFound />;
  }

  return (
    <HomeTopBar>
      <main className="max-w-2/3">
        <MyProfilePage />
      </main>
    </HomeTopBar>
  );
};
