import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Flex, HeadingOne } from '../../atoms';
import { EditPostIcon } from '../../svgs/EditPost';
import { DeletePostIcon } from '../../svgs/DeletePost';
import {
  subscribeToNews,
  unsubscribeFromNews,
  useAppDispatch,
  useAppSelector
} from '../../state';
import { openModal } from '../../state/actions/modalsActions';
import { CommunityState } from '../../api';
import { useLocation, useNavigate } from 'react-router-dom';
import { texts } from '../../text';
import { selectCommunityData } from '../../state/selectors/communitySelector';
import { useDeleteCommunity } from './hooks/useDeleteCommunity';
import { selectIsUserAdmin } from '../../state/selectors/userSelector';
import {
  ConfirmationModalWrapper,
  NotificationModalWrapper
} from '../../components/modals';
import { Tooltip } from '../../components/core/tooltip';
import { useAuth } from '../../state/hooks/useAuth';
import { useCountdownForNavigate } from '../post/hooks/useCountdownForNavigate';
import { Subscribe } from '../../svgs/Subscribe';
import { Unsubscribe } from '../../svgs/Unsubscribe';
import { clearSubscriptions } from '../../state/slices/userReducer';

interface CommunityTitlePlaceProps {}

export const CommunityTitlePlace: FC<CommunityTitlePlaceProps> = ({}) => {
  const communityData = useAppSelector(selectCommunityData);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const handleOpenModal = (modalName: string) => {
    dispatch(openModal(modalName));
  };

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [isConfirmationModalOpen, setIsConfirmationModalOpen] =
    useState<boolean>(false);
  const [isNotificationModalOpen, setIsNotificationModalOpen] =
    useState<boolean>(false);

  const [confirmationType, setConfirmationType] = useState<
    'delete' | 'subscribe' | 'unsubscribe' | null
  >(null);

  const countdown = useCountdownForNavigate(
    isNotificationModalOpen
      ? confirmationType === 'delete'
        ? 7
        : 5 // 5 seconds for unsubscribe
      : 0
  );

  const isUserAdmin = useAppSelector(selectIsUserAdmin);
  const { user } = useAuth();
  const isUserCreator = useMemo(() => {
    return user && communityData?.createdByUser?.id
      ? Number(user.id) === Number(communityData.createdByUser.id)
      : false;
  }, [user, communityData?.createdByUser?.id]);

  const handleSuccess = () => {
    setIsLoading(false);
    setIsConfirmationModalOpen(false);
    setIsNotificationModalOpen(true);
  };

  const handleError = (error: any) => {
    setIsLoading(false);
    if (!user) {
      openLoginModal();
      setIsNotificationModalOpen(false);
    } else {
      // eslint-disable-next-line no-console
      console.log('Error deleting community:', error);
    }
  };

  const deleteCommunity = useDeleteCommunity(handleSuccess, handleError);

  const handleSubscriptionAction = async () => {
    if (communityData?.urlKey) {
      setIsLoading(true);
      try {
        if (confirmationType === 'subscribe') {
          await dispatch(
            subscribeToNews({ urlKey: communityData.urlKey })
          ).unwrap();
          setIsNotificationModalOpen(false);
        } else if (confirmationType === 'unsubscribe') {
          await dispatch(
            unsubscribeFromNews({ urlKey: communityData.urlKey })
          ).unwrap();
          setIsNotificationModalOpen(true);
        }
        dispatch(clearSubscriptions()); //For subs list
        setIsLoading(false);
        setIsConfirmationModalOpen(false);
      } catch (error) {
        handleError(error);
      }
    }
  };

  const handleConfirmDelete = () => {
    if (communityData?.urlKey) {
      setIsLoading(true);
      deleteCommunity({ urlKey: communityData.urlKey });
    }
  };

  const handleNotificationClose = () => {
    setIsNotificationModalOpen(false);
    navigate(-1);
  };

  const closeNotificationModal = () => {
    if (confirmationType === 'delete') {
      handleNotificationClose();
    } else {
      setIsNotificationModalOpen(false);
    }
  };

  const handleSubscribeToNews = async () => {
    setConfirmationType('subscribe');
    if (!communityData?.urlKey) return;
    setIsLoading(true);
    try {
      await dispatch(
        subscribeToNews({ urlKey: communityData.urlKey })
      ).unwrap();
      dispatch(clearSubscriptions()); //For subs list
      setIsLoading(false);
    } catch (error) {
      handleError(error);
    }
  };

  const handleUnSubscribeToNews = async () => {
    setConfirmationType('unsubscribe');
    if (!communityData?.urlKey) return;
    setIsLoading(true);
    try {
      await dispatch(
        unsubscribeFromNews({ urlKey: communityData.urlKey })
      ).unwrap();
      dispatch(clearSubscriptions()); //For subs list
      setIsLoading(false);
      setIsNotificationModalOpen(true);
    } catch (error) {
      handleError(error);
    }
  };

  const handleCloseModal = () => {
    setIsConfirmationModalOpen(false);
    setConfirmationType(null);
  };

  const handleDeleteModalShow = () => {
    setConfirmationType('delete');
    setIsConfirmationModalOpen(true);
  };

  const handleOpenModalHash = useCallback(
    (modalName: string) => {
      const currentSearch = location.search;
      navigate(`${currentSearch}#${modalName}`, { replace: true });
    },
    [location.search, navigate]
  );

  const openLoginModal = useCallback(
    () => handleOpenModalHash('login'),
    [handleOpenModalHash]
  );

  useEffect(() => {
    if (countdown === 0 && isNotificationModalOpen) {
      setIsNotificationModalOpen(false);
      if (confirmationType === 'delete') {
        navigate(-1);
      }
    }
  }, [confirmationType, countdown, isNotificationModalOpen, navigate]);

  useEffect(() => {
    const pathParts = location.pathname.split('/');
    const action = pathParts[pathParts.length - 1];
    if (action === 'subscribe' || action === 'unsubscribe') {
      setConfirmationType(action);
      setIsConfirmationModalOpen(true);
    }
  }, [location]);

  return (
    <main className="page-content pt-10 pb-8 px-4">
      <div className="max-w-3xl m-auto">
        <Flex direction="flex-row w-full">
          <Flex className="w-full">
            <div className="flex flex-row items-start justify-between mb-2">
              <div className="">
                <HeadingOne color="black" className="mb-0">
                  {communityData?.name}
                </HeadingOne>
              </div>
              {communityData?.state === CommunityState.Active && (
                <div className="flex flex-row">
                  {user && (isUserAdmin || isUserCreator) && (
                    <div
                      onClick={() => handleOpenModal('updateCommunity')}
                      className="mr-1 cursor-pointer"
                    >
                      <Tooltip className="tooltipSm" text={'Edit'}>
                        <EditPostIcon />
                      </Tooltip>
                    </div>
                  )}
                  {user && (isUserAdmin || isUserCreator) && (
                    <div
                      className="cursor-pointer"
                      onClick={handleDeleteModalShow}
                    >
                      <Tooltip className="tooltipSm" text={'Delete'}>
                        <DeletePostIcon className="cursor-pointer" />
                      </Tooltip>
                    </div>
                  )}
                </div>
              )}
            </div>
            <div className="flex flex-col tablet:flex-row laptop:flex-row desktop:flex-row justify-between items-center">
              <div className="text-primary-500 text-sm mr-1">
                #C[{communityData?.name}]
              </div>
              {!user ? (
                <div
                  className="cursor-pointer flex items-center"
                  onClick={openLoginModal}
                >
                  <Tooltip
                    text="You must be logged in to subscribe to the latest community news."
                    className="tooltipSubs"
                  >
                    <span className="text-gray-400 flex items-center">
                      Subscribe <Subscribe />
                    </span>
                  </Tooltip>
                </div>
              ) : !communityData?.isSubscribedToNews ? (
                <div
                  onClick={handleSubscribeToNews}
                  className="cursor-pointer flex items-center"
                >
                  <Tooltip
                    text="Stay informed with the latest research, discussions, and updates from this community!"
                    className="tooltipSubs"
                  >
                    Subscribe <Subscribe />
                  </Tooltip>
                </div>
              ) : (
                <div
                  onClick={handleUnSubscribeToNews}
                  className="cursor-pointer flex items-center"
                >
                  <Tooltip
                    text="Unsubscribe if you no longer wish to receive updates from this community. You’re welcome back anytime!"
                    className="tooltipSubs"
                  >
                    Unsubscribe <Unsubscribe />
                  </Tooltip>
                </div>
              )}
            </div>

            {communityData?.state !== CommunityState.Active && (
              <div className="text-sm mt-10">{texts.disabledCommunity}</div>
            )}
          </Flex>
        </Flex>
      </div>
      {isConfirmationModalOpen && (
        <ConfirmationModalWrapper
          isOpen={isConfirmationModalOpen}
          title={
            confirmationType === 'subscribe'
              ? 'Subscribe to Updates'
              : confirmationType === 'unsubscribe'
                ? 'Unsubscribe from Updates'
                : 'Confirm Deletion'
          }
          message={
            confirmationType === 'subscribe'
              ? // eslint-disable-next-line quotes
                "You're about to subscribe to community news and updates. Stay informed with the latest announcements and events!"
              : confirmationType === 'unsubscribe'
                ? 'Are you sure you want to unsubscribe from community news and updates? You may miss important announcements and events.'
                : 'Are you sure you want to delete this community?'
          }
          onConfirm={
            confirmationType === 'delete'
              ? handleConfirmDelete
              : handleSubscriptionAction
          }
          onClose={handleCloseModal}
          loading={isLoading}
        />
      )}
      {isNotificationModalOpen && (
        <NotificationModalWrapper
          isOpen={isNotificationModalOpen}
          title="Success"
          message={
            confirmationType === 'subscribe'
              ? ''
              : confirmationType === 'unsubscribe'
                ? `You are no longer subscribed to ${communityData?.name}.`
                : `Community deleted successfully. Close the modal window to navigate back, or you will be redirected automatically in ${countdown}s.`
          }
          onClose={closeNotificationModal}
        />
      )}
    </main>
  );
};
