import classNames from 'classnames';
import React, { useState, useRef, useEffect } from 'react';
import { Tooltip } from '../core/tooltip';
import { QuestionMark } from '../../svgs';
import { useAppSelector } from '../../state';
import { selectPaperReferencesCitationsLoading } from '../../state/selectors/paperSelector';
import { Loading } from '../core';
import {
  selectCitesCitedByViewMode,
  selectSubscriptionsViewMode
} from '../../state/selectors/viewModeSelector';

type AccordionItemStickyCitesCitedByProps = {
  title: string;
  isExpanded: boolean;
  onToggle: () => void;
  children: React.ReactNode;
  disabled?: boolean;
  tooltipText?: string;
  isCitesCitedBy?: boolean;
};

export const AccordionItemStickyCitesCitedBy: React.FC<
  AccordionItemStickyCitesCitedByProps
> = ({
  title,
  isExpanded,
  onToggle,
  children,
  disabled = false,
  tooltipText,
  isCitesCitedBy
}) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState(0);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [isSticky, setIsSticky] = useState(false);
  const citesCitedByRadioState = useAppSelector(
    (state) => state.tabs.citesCitedByRadioState
  );
  const paperReferencesCitationsLoading = useAppSelector(
    selectPaperReferencesCitationsLoading
  );
  const childRef = useRef<HTMLDivElement>(null);

  const viewMode = useAppSelector(
    isCitesCitedBy ? selectCitesCitedByViewMode : selectSubscriptionsViewMode
  );

  useEffect(() => {
    const childEl = childRef.current;
    const additionalSpace = 30; //For fix incorrect hieght of accordion content with masonry on mobile resolution
    if (isExpanded && childEl) {
      // For correct height calculation when changing the tab with triple view mode
      requestAnimationFrame(() => {
        const newHeight = childEl.scrollHeight;
        setHeight(newHeight + additionalSpace);
      });
    } else {
      setHeight(0);
    }
  }, [isExpanded, citesCitedByRadioState, children, viewMode]);

  useEffect(() => {
    const handleScroll = () => {
      if (buttonRef.current) {
        const buttonTop = buttonRef.current.getBoundingClientRect().top;
        setIsSticky(buttonTop <= 0);
      }
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll();
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className="accordion-item box-border px-6 pt-8 pb-6 mb-4 ">
      <button
        ref={buttonRef}
        onClick={disabled ? undefined : onToggle}
        disabled={disabled}
        className={classNames(
          'accordion-title-wrap flex justify-between items-center sticky-header',
          {
            'cursor-pointer shadow-xl py-2 px-4 ': !disabled && isSticky,
            'shadow-none': !isSticky
          }
        )}
      >
        <span className="accordion-title flex items-center">
          <span className={classNames({ 'opacity-50': disabled })}>
            {title}
          </span>
          {disabled ? (
            tooltipText && !paperReferencesCitationsLoading ? (
              <Tooltip
                className="tooltipComments tooltipCitations ml-5"
                text={tooltipText}
              >
                <QuestionMark />
              </Tooltip>
            ) : (
              <Loading className="citationLoading"></Loading>
            )
          ) : null}
        </span>
        <span
          className={classNames('accordion-toggle', {
            'accordion-toggle-active': isExpanded,
            'opacity-50': disabled
          })}
        >
          {isExpanded ? '-' : '+'}
        </span>
      </button>
      <div
        ref={contentRef}
        className="accordion-content text-sm mt-2"
        style={{
          height: isExpanded ? `${height}px` : '0px',
          transition: 'height 0.3s ease'
        }}
      >
        <div ref={childRef} className="">
          {children}
        </div>
      </div>
    </div>
  );
};
