import { useAppDispatch, useAppSelector } from '../../state';
import { setDiscussionSortMethod } from '../../state/actions/sortingActions';
import { FC, useEffect, useState } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import qs from 'qs';
import { DiscussionCommunityList } from './discussions-community-list';
import { DiscussionTopicList } from './discussions-topic-list';
import { ViewModeToggle } from '../viewModeToggle';
import { useQueryParams } from '../../hooks/useQueryParams';
import { SortingButtonsContainer } from '../sortingButtons/sortingButtonsContainer';
import {
  DISCUSSION_SORT_METHOD_DISPLAY_NAMES,
  DiscussionSortMethod
} from '../../utils/sortingConst';
import { selectGeneralViewMode } from '../../state/selectors/viewModeSelector';

interface DiscussionsContentProps {
  isCommunity?: boolean;
}

export const DiscussionsList: FC<DiscussionsContentProps> = ({
  isCommunity
}) => {
  const dispatch = useAppDispatch();
  const { urlKey } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const viewMode = useAppSelector(selectGeneralViewMode);
  const isTripleView = viewMode === 'triple';

  const queryParams = useQueryParams();
  const initialPage = queryParams.p ? parseInt(queryParams.p as string, 10) : 1;
  const [currentDiscussionPage, setCurrentDiscussionPage] =
    useState(initialPage);
  const discussionSortMethod = useAppSelector(
    (state) => state.sort.discussionSortMethod
  );

  const updatePage = (newPage: number) => {
    setCurrentDiscussionPage(newPage);
    const newSearch = qs.stringify({ p: newPage }, { addQueryPrefix: true });
    navigate({ pathname: location.pathname, search: newSearch });
  };

  const handleSortingDiscussionsChange = (method: DiscussionSortMethod) => {
    dispatch(setDiscussionSortMethod(method));
  };

  useEffect(() => {
    const newPage = queryParams.p ? parseInt(queryParams.p as string, 10) : 1;
    setCurrentDiscussionPage(newPage);
  }, [location.search, queryParams]);

  return (
    <div className="w-full h-full">
      <SortingButtonsContainer
        sortMethods={DISCUSSION_SORT_METHOD_DISPLAY_NAMES}
        currentSortMethod={discussionSortMethod}
        onSortMethodChange={handleSortingDiscussionsChange}
      />
      <ViewModeToggle />
      {isCommunity ? (
        <DiscussionCommunityList
          updatePage={updatePage}
          urlKey={urlKey}
          isTripleView={isTripleView}
          currentDiscussionPage={currentDiscussionPage}
          discussionSortMethod={discussionSortMethod}
        />
      ) : (
        <DiscussionTopicList
          updatePage={updatePage}
          urlKey={urlKey}
          isTripleView={isTripleView}
          currentDiscussionPage={currentDiscussionPage}
          discussionSortMethod={discussionSortMethod}
        />
      )}
    </div>
  );
};
