import React from 'react';
import { Link } from 'react-router-dom';

interface UserDropdownProps {
  visible: boolean;
  onMouseDown: (event: React.MouseEvent<HTMLDivElement>) => void;
  onLogoutClick: () => void;
}

export const UserDropdown: React.FC<UserDropdownProps> = ({
  visible,
  onMouseDown,
  onLogoutClick
}) => {
  if (!visible) {
    return null;
  }

  return (
    <div
      className="top-full absolute right-0 mt-2 text-sm font-medium"
      onMouseDown={onMouseDown}
    >
      <div className="bg-white shadow-md">
        <Link
          to="/profiles/my/general"
          className="block hover:text-primary-500 px-4 py-2 transition-colors duration-200 cursor-pointer"
        >
          My Profile
        </Link>
        <div
          className="hover:text-primary-500 px-4 py-2 transition-colors duration-200 cursor-pointer"
          onClick={onLogoutClick}
        >
          Logout
        </div>
      </div>
    </div>
  );
};
