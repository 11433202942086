import { AppState } from '../store';

export const selectGeneralViewMode = (state: AppState) =>
  state.viewmode.generalMode;

export const selectCitesCitedByViewMode = (state: AppState) =>
  state.viewmode.citesCitedByMode;

export const selectSubscriptionsViewMode = (state: AppState) =>
  state.viewmode.subscriptionsMode;
