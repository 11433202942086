import { FC } from 'react';
import { ViewLine } from '../../svgs/ViewLine';
import { ViewThreeInLine } from '../../svgs/ViewThreeInLine';
import {
  setSubscriptionsViewMode,
  useAppDispatch,
  ViewMode
} from '../../state';

interface SubscriptionsViewToggleProps {
  viewMode: 'single' | 'triple';
}

export const SubscriptionsViewToggle: FC<SubscriptionsViewToggleProps> = ({
  viewMode
}) => {
  const dispatch = useAppDispatch();
  const isTripleView = viewMode === 'triple';

  const handleChangeViewMode = (newMode: ViewMode) => {
    dispatch(setSubscriptionsViewMode(newMode));
  };

  return (
    <div className="flex justify-center w-full pt-0 mb-2 ">
      <div className="flex w-full justify-end">
        <div className="flex justify-end btnsOrder">
          <button
            onClick={() => handleChangeViewMode('single')}
            className={!isTripleView ? 'active-class' : ''}
            aria-label="Single View"
          >
            <ViewLine />
          </button>
          <button
            onClick={() => handleChangeViewMode('triple')}
            className={isTripleView ? 'active-class' : ''}
            aria-label="Triple View"
          >
            <ViewThreeInLine />
          </button>
        </div>
      </div>
    </div>
  );
};
