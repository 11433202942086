import React, { FC } from 'react';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import { Link } from 'react-router-dom';

import {
  subscribeToNews,
  unsubscribeFromNews,
  useAppDispatch
} from '../../state';
import { Tooltip } from '../core/tooltip';
import { Subscribe } from '../../svgs/Subscribe';
import { Unsubscribe } from '../../svgs/Unsubscribe';
import { formatDate } from '../../utils/userPostHelper';
import { clearSubscriptions } from '../../state/slices/userReducer';

interface ICommunitySubscription {
  subscriptionId: number;
  createdUtc: string;
  deletedUtc: string | null;
  communityName: string;
  communityUrlKey: string;
}

type ConfirmationType = 'subscribe' | 'unsubscribe' | null;

interface SubscriptionsListProps {
  items: ICommunitySubscription[];
  viewMode: 'single' | 'triple';
  setConfirmationType: React.Dispatch<React.SetStateAction<ConfirmationType>>;
  setIsNotificationModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setUnsubscribedCommunityName: React.Dispatch<
    React.SetStateAction<string | null>
  >;
}

export const SubscriptionsList: FC<SubscriptionsListProps> = ({
  items,
  viewMode,
  setConfirmationType,
  setIsNotificationModalOpen,
  setUnsubscribedCommunityName
}) => {
  const dispatch = useAppDispatch();

  const isTripleView = viewMode === 'triple';

  const handleUnsubscribe = async (
    communityUrlKey: string,
    communityName: string
  ) => {
    await dispatch(unsubscribeFromNews({ urlKey: communityUrlKey })).unwrap();
    setConfirmationType('unsubscribe');
    setUnsubscribedCommunityName(communityName);
    dispatch(clearSubscriptions());
    setIsNotificationModalOpen(true);
  };

  const handleReSubscribe = async (communityUrlKey: string) => {
    await dispatch(subscribeToNews({ urlKey: communityUrlKey })).unwrap();
    setConfirmationType('subscribe');
    dispatch(clearSubscriptions());
    setIsNotificationModalOpen(true);
  };

  return (
    <ResponsiveMasonry
      columnsCountBreakPoints={{
        350: 1,
        600: isTripleView ? 2 : 1,
        900: isTripleView ? 3 : 1
      }}
      className="w-full cites-citedby-tabs-container"
    >
      <Masonry gutter="14px">
        {items.map((sub) => {
          const subscribedDate = formatDate(sub.createdUtc);
          const unsubscribedDate = sub.deletedUtc
            ? formatDate(sub.deletedUtc)
            : null;
          const isCurrentlySubscribed = !sub.deletedUtc;

          return (
            <div
              key={sub.subscriptionId}
              className="subscription-item item border border-gray-200 rounded p-4 shadow-sm"
            >
              <div className="itemTitle font-bold flex mb-3 ">
                <div className="text-sm text-gray-900 hover:text-primary-500 transition-smooth-color leading-tight cursor-pointer">
                  <Link
                    to={`/community/${sub.communityUrlKey}/papers`}
                    className="community-link font-bold"
                  >
                    {sub.communityName}
                  </Link>
                </div>
              </div>
              <div className="subscription-dates text-sm text-gray-600 mt-2">
                <div>Subscribed: {subscribedDate}</div>
                {unsubscribedDate && (
                  <div>Unsubscribed: {unsubscribedDate}</div>
                )}
              </div>
              <div className="subscription-actions mt-3">
                {isCurrentlySubscribed ? (
                  <div
                    onClick={() =>
                      handleUnsubscribe(sub.communityUrlKey, sub.communityName)
                    }
                    className="cursor-pointer flex items-center"
                  >
                    <Tooltip
                      text="Unsubscribe if you no longer wish to receive updates from this community. You’re welcome back anytime!"
                      className="tooltipSubs"
                    >
                      Unsubscribe <Unsubscribe />
                    </Tooltip>
                  </div>
                ) : (
                  <div
                    onClick={() => handleReSubscribe(sub.communityUrlKey)}
                    className="cursor-pointer flex items-center"
                  >
                    <Tooltip
                      text="Stay informed with the latest research, discussions, and updates from this community!"
                      className="tooltipSubs"
                    >
                      Subscribe <Subscribe />
                    </Tooltip>
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </Masonry>
    </ResponsiveMasonry>
  );
};
