import { createAsyncThunk } from '@reduxjs/toolkit';
import { env } from '../../env';
import { ISubscriptions, IUserProfileIsAdmin } from '../../types/user.type';
import { sendProtectedRequest } from './sendProtectedRequest';

export const getIsUserAdmin = createAsyncThunk(
  'get/getIsUserAdmin',
  async (_, { rejectWithValue }) => {
    const url = `${env.apiUrl}/api/v1/myprofile`;

    try {
      const response = await sendProtectedRequest(url, {
        method: 'GET'
      });
      if (!response.ok) {
        throw new Error('Failed to fetch user roles');
      }
      const data: IUserProfileIsAdmin = await response.json();
      return data;
    } catch (error) {
      if (error instanceof Error) {
        return rejectWithValue(error.message);
      } else {
        return rejectWithValue('An unknown error occurred');
      }
    }
  }
);

export const getUserProfile = createAsyncThunk<
  IUserProfileIsAdmin,
  void,
  { rejectValue: string }
>('userProfile/getUserProfile', async (_, { rejectWithValue }) => {
  try {
    const response = await sendProtectedRequest(
      `${env.apiUrl}/api/v1/myprofile`,
      {
        method: 'GET'
      }
    );

    if (!response.ok) {
      const errorData = await response.json();
      const errorMessage = errorData.message || 'Failed to fetch the profile';
      throw new Error(errorMessage);
    }

    const data: IUserProfileIsAdmin = await response.json();
    return data;
  } catch (error: any) {
    return rejectWithValue(error.message || 'Failed to fetch the profile');
  }
});

export const getSubscriptions = createAsyncThunk(
  'get/getSubscriptions',
  async (_, { rejectWithValue }) => {
    const url = `${env.apiUrl}/api/v1/myprofile/subscriptions`;

    try {
      const response = await sendProtectedRequest(url, {
        method: 'GET'
      });

      if (!response.ok) {
        throw new Error('Failed to fetch subscriptions');
      }

      const data: ISubscriptions = await response.json();
      return data;
    } catch (error) {
      if (error instanceof Error) {
        return rejectWithValue(error.message);
      } else {
        return rejectWithValue('An unknown error occurred');
      }
    }
  }
);
